.cookie-info {
  align-items: center;
  background: #FFFFFF;
  bottom: 0;
  display: flex;
  min-height: 45px;
  padding: 12px;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in-out;
  width: 100%;
  z-index: 160000005; }
  @media (max-width: 767px) {
    .cookie-info {
      align-items: flex-start;
      flex-direction: column;
      padding: 20px 15px 15px; } }
  .cookie-info.hidden {
    transform: translate3d(0, 100%, 0);
    transition: transform 0.2s ease-in-out; }
  .cookie-info__button {
    background: transparent;
    border: none;
    color: #DB3645;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 0 10px;
    outline: none;
    padding: 0;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .cookie-info__button {
        margin: 0; } }
    .cookie-info__button:hover {
      color: #bc2230; }
  .cookie-info__link {
    color: red;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    min-height: 45px;
    text-align: center;
    text-decoration: none; }
    .cookie-info__link:hover {
      color: #a71e2a; }
  .cookie-info__text {
    color: #1c1417;
    flex: auto;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin: 0 50px;
    text-align: center; }
    @media (max-width: 767px) {
      .cookie-info__text {
        margin: 20px auto 0;
        text-align: left; } }
